.import-Heading {

    text-align: center;
    padding-top: 40px;
    padding-bottom: 50px;
    
}

.upload-center {
    background-color: rgb(240, 237, 236);
    min-height: 60vh;
    display:flex;
}
.upload-center:hover{
    background-color: rgb(228, 225, 225);
}
.border-green-600 {

    border:seagreen;
    border-style: solid;
}

.border-gray-600 {
    border:rgb(167, 167, 167);
    border-style: dotted;
}

.centered-text {
    margin:auto;
    vertical-align: middle;
    font-size: 25px;
}