.admin-heading {
    text-align: center;
    margin-top: 100px;
    margin-bottom: 80px;

}

.card-header {

    background-color: rgb(255 255 255)!important;
}

.admin-card-shadow {

    -webkit-box-shadow: 0px 0px 10px 2px rgba(116,116,116,0.19);
    box-shadow: 0px 0px 10px 2px rgba(116,116,116,0.19);
    transition: all .2s ease-in-out; 
    min-height: 210px;
}

.admin-card-shadow:hover {
    transform: scale(1.1);
    
}