.headerSysInfo {
    text-align: center;
}

.paragraphCenter{
    text-align: center;
}

.info-height{
    min-height: 260px;
    -webkit-box-shadow: 0px 0px 10px 2px rgba(116,116,116,0.20);
    box-shadow: 0px 0px 10px 2px rgba(160, 108, 108, 0.20);
    transition: all .2s ease-in-out; 
}

