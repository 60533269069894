.course-goals h3{
    text-align: center;

}

.newSystemHeader {

    margin-bottom: 50px;
}

.admin_panel_no_left_margin {
    padding-left: 0px;
}

.admin-panel-background {

    background-color: rgb(55, 24, 87);
    color : white;
    min-height: 80vh; 

}

.edit-user-header{
    
    text-align: center;
    padding-top: 50px;
}