.goal-list {
    list-style: none;
    margin: 2rem;
    padding: 0;
}

.goal-list li {
    margin: .5rem 0;
    border: 1px solid  rgba(53, 139, 132, 0.801);
    padding: 1rem;
}