

.panel-heading {
    margin-bottom: 33px;
    padding-bottom: 33px;
    margin-top: 0px;
    padding-top: 45px;
}


.admin-icon {
    padding-right: 15px;
}

.admin-link:hover {

    color: #a991d4;
}