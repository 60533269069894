.add-user-heading {

    text-align: center;
    margin-top: 80px;
    margin-bottom: 30px;
}

.add_user-form-container {
    margin: 0px 50px 0px 50px;
    max-width: 45vw;
}

.eye-background:hover{
    font-size: 104%;
    background-color: #ebebeb;
}

